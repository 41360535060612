// -------------------
// Internal Variables
// -------------------

$blue-base: #102c43;
$black-base: #000000;
$silver-base: #f8fafb;
$gray-base: #444444;

$white: #ffffff;
$black: #000000;
$red: #ff0000;
$blue: #0000ff;
$button-blue: #007d97;
$filter-content: #f8fafb;
$border-grey: #d3dee4;
$box-shadow: rgba(0, 0, 0, 0.2);
$button-submit-green: #008b4d;
$button-disbaled-color: rgba(206, 214, 224, 0.5);
$button-submit-font: #999;

// -------------------
// Global Variables
// -------------------

$color-primary: $blue-base;

$color-link: $color-primary;

$color-background: $white;

$color-text-dark: $black;

$color-text-light: $white;

$color-chart: $silver-base;

$color-chart-border: #d3dee4;

$color-chart-fact-bg: #1F77B4;
$color-chart-fact-text: #ffffff;

$color-error: #FFCCCC;
$color-info: #EAF9BD;

$color-nav: $color-primary;

$overlay: rgba(0, 0, 0, 0.4);

$shadow: rgba(0, 0, 0, 0.1);
