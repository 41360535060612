.map,
.avi-chart {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  path.line {
    fill: none;
    stroke-width: 1.5px;
  }
}

.dc-chart {
  path.line {
    fill: none;
    stroke-width: 1.5px;
  }
}

.dc-legend-item {
  text {
    font-family: sans-serif;
    text-transform: uppercase;
    font-size: 10px;
  }

}