.avi-data-table {
  width: 100%;
  font: normal 0.8em arial, sans-serif;
  tr:nth-child(odd) {
    background-color: #ffffff;
  }

  tr:nth-child(even) {
    background-color: #d0e1f1;
  }

  th {
    font: bold 1.2em;
    background-color: #1f77b4;
    color: #efefef;
    text-transform: uppercase;
    padding: 1em;
    cursor: pointer;
    > * {
      display: inline-block;
      vertical-align: middle;
    }
    &.sorting-by {
      background-color: lighten(#1f77b4, 10);
    }
    &.order-desc,
    &.order-asc {
      &:after {
        content: '';
        display: inline-block;
        margin-left: 5px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
      }
    }
    &.order-asc {
      &:after {
        border-top: 5px solid darken(#1f77b4, 10);
      }
    }
    &.order-desc {
      &:after {
        border-bottom: 5px solid darken(#1f77b4, 10);
      }
    }
  }

  td {
    padding: 0.5em;
  }
}

.avi-data-table-nav-button {
  margin: 0em 1em 0.5em 0;
}
