@import '../../Theme/theme.scss';
.chart-tile,
.avi-chart-tile {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.chart-tile-header,
.avi-chart-tile-header {
  height: 35px;
  margin: 0;
  padding: 0px 10px 10px 10px;
  border: 0;
}

.chart-tile-header h4,
.avi-chart-tile-header h4 {
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 35px;
}

.chart-tile-content,
.avi-chart-tile-content {
  flex-grow: 1;
  margin: 0;
  padding: 0;
  border: 0;
}

.chart-tile-footer,
.avi-chart-tile-footer {
  height: 40px;
  padding-right: 5px;
  padding-left: 5px;
  margin: 0;
  border: 0;
}

.chart-action-bar,
.avi-chart-action-bar {
  text-align: right;
}

.chart-action-link,
.avi-chart-action-link {
  font-size: 0.6em;
  text-transform: uppercase;
  color: #666666;
  text-decoration: none;
  cursor: pointer;
}

a.chart-action-link:hover,
a.avi-chart-action-link:hover {
  color: #0000ff;
}

.clear-both,
.avi-clear-both {
  display: block;
  clear: both;
}
