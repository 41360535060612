@import '../../Theme/theme';

.table-tile,
.avi-table-tile {
  @include tile();
  width: 100%;
  margin: 20px;
  overflow: hidden;
}

.table-container,
.avi-table-container {
  display: block;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
}

.data-table,
.avi-data-table {
  display: table;
  width: 100% !important;
  overflow-y: auto;
}
