.dc-chart .row text {
  fill: black;
}

.dc-chart g.row text {
  fill: black;
}


.row-chart-grouped {

  .legend {
    fill: black;
    font: 14px sans-serif;
    text-anchor: start;
    font-size: 12px;
  }
  
  text {
    fill: white;
    font: 10px sans-serif;
    text-anchor: end;
  }
  
  .label {
    fill: black;
    font: 14px sans-serif;
    text-anchor: end;
  }
  
  .bar:hover {
    fill: brown;
  }
  
  .axis path,
  .axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
  }

}

.row-chart-stacked {

}