@import '../../Theme/theme.scss';
.fact-tile-small,
.avi-fact-tile-small {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $color-chart-fact-bg;
  color: $color-chart-fact-text;
}

.fact-header,
.avi-fact-header {
  display: block;
  overflow: hidden;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 0px;
  height: 25px;
}

.fact-header h4,
.avi-fact-header h4 {
  margin: 0;
  padding: 0;
  line-height: 25px;
}

.fact-container,
.avi-fact-container {
  flex-grow: 1;
  border: 0;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
}
