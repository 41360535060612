div.avi-chart.chart.pivot-table {
  table,
  th,
  td {
    border-top: 1px solid;
  }

  th,
  td {
    padding: 0.3em;
  }

  table {
    width: 100%;
    height: auto;
    border-collapse: collapse;

    thead {
      color: #ffffff;
      font-weight: bold;

      th {
        word-wrap: normal;
        vertical-align: bottom;
      }
    }

    tbody,
    tfoot {
      tr {
        vertical-align: top;
      }

      tr:first-child {
        text-align: center;
      }

      td {
        text-align: right;
      }

      td:last-child {
        font-style: italic;
      }

      td:first-child {
        text-align: left;
      }
    }

    tfoot {
      td:last-child {
        font-style: normal;
      }

      tr:first-child {
        border-top: 2px solid;
        border-bottom: 3px double;
        td {
          font-weight: bold;
        }
      }
    }
  }
}
