.dc-chart {
  path.line {
    fill: none;
    stroke-width: 1.5px;
  }
}

.avi-chart.legend {
  display: none;
  &.show {
    display: block;
  }

  .dc-html-legend {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
    .legend-item{
      flex: 1 0 220px;
      margin: 8px;

      .dc-legend-item-color {
        height: 18px;
        width: 18px;
        position: absolute;
      }

      .dc-legend-item-label {
        margin-left: 22px;
      }
    }
  }
}
