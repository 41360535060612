$dashboard-color: #ff0000;
$tile-color: #f0f0f0;
$tile-border-light: lighten($tile-color, 2%);
$tile-border-dark: darken($tile-color, 2%);

.grid,
.avi-grid {
  //background-color: $dashboard-color;
}

.flex-row,
.avi-flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-col-container,
.avi-flex-col-container {
}

.flex-col,
.avi-flex-col {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

@media (max-width: 512px) {
  .flex-row,
  .flex-col,
  .avi-flex-row,
  .avi-flex-col {
    display: block;
  }
}

.tile,
.avi-tile {
  min-height: 10vh;
  margin: 0px;
  border: 0px;
}

.grid-footer,
.avi-grid-footer {
  // float: left;
  clear: left;
}

.tile-padding,
.avi-tile-padding {
  background-color: $tile-color;
  height: 100%;
  padding: 5px;
}

.tile-content,
.avi-tile-content {
  background-color: $tile-color;
  height: 100%;
  border-top: 2px solid $tile-border-light;
  border-left: 2px solid $tile-border-light;
  border-right: 2px solid $tile-border-dark;
  border-bottom: 2px solid $tile-border-dark;
}

.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.flex3 {
  flex: 3;
}
.flex4 {
  flex: 4;
}
.flex5 {
  flex: 4;
}
.flex6 {
  flex: 4;
}
.flex7 {
  flex: 4;
}
.flex8 {
  flex: 4;
}

.row1 {
  height: 30vh;
}

.row2 {
  height: 60vh;
}

.row3 {
  height: 90vh;
}

span.grid-footer,
span.avi-grid-footer {
  clear: left;
  display: block;
}
