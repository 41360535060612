@import './colors.scss';
@import './fonts.scss';

@mixin form-control-style {
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px $color-chart-border solid;
  color: $color-text-dark;
  background-color: $white;
  font-size: 14px;
  border-radius: 0px;
  outline: none;

  &:focus {
    border: 1px solid darken($color-chart-border, 15%);
  }
}

// ----------------------------
// Font mixin
// ----------------------------

@mixin main-font-style(
  $weight: $font-weight-regular,
  $size: $font-size-xx-large,
  $color: $color-text-dark
) {
  font-family: $font-family-main;
  font-weight: $weight;
  font-size: $size;
  color: $color;
}
// ----------------------------
// Headline mixin
// ----------------------------
@mixin headline($weight: $font-weight-bold, $size: $font-size-xx-large, $color: $font-color-main) {
  @include main-font-style($weight: $weight, $size: $size, $color: $color);
  margin: 0;
  padding: 0;
}
// ----------------------------
// Size mixin
// ----------------------------
@mixin size($width: $width, $height: $height) {
  height: $height;
  width: $width;
}
@mixin square($size) {
  @include size($size, $size);
}
// ----------------------------
// Pseudo mixin
// ----------------------------
@mixin pseudo($display: block, $pos: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}
// ----------------------------
// positioning mixin
// ----------------------------
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
@mixin absolute($args...) {
  @include position(absolute, $args...);
}
@mixin relative($args...) {
  @include position(relative, $args...);
}
@mixin fixed($args...) {
  @include position(fixed, $args...);
}
// // ----------------------------
// // Tile mixin
// // ----------------------------
@mixin tile() {
  background-color: $color-chart;
  height: 100%;
  width: 100%;
}
